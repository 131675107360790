<template>
  <v-menu
    ref="menu_hora"
    v-model="menu_hora"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="hora"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="hora"
        :label="label"
        append-icon="mdi-clock-time-four-outline"
        v-bind="attrs"
        v-on="on"
        dense
        clearable
        v-mask="'##:##'"
        :disabled="disabled"
        @blur="parseTime(hora)"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu_hora"
      v-model="timePicker"
      full-width
      format="24hr"
      @click:hour="selectedHour"
      @click:minute="$refs.menu_hora.save(timePicker)"
      :disabled="disabled"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "TimeField",

  props: {
    hora_sync: {
      type: String,
      default: "",
    },
    entrada: {
      type: [Date, String],
      required: false,
    },
    saida_intervalo: {
      type: [Date, String],
      required: false,
    },
    volta_intervalo: {
      type: [Date, String],
      required: false,
    },
    saida: {
      type: [Date, String],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Hora:",
    },
    hora_start: {
      type: String,
      default: "00:00",
    },
  },

  data() {
    return {
      hora: "",
      menu_hora: false,
      timePicker: null,
    };
  },

  watch: {
    hora() {
      if (this.hora) {
        this.$emit("update:hora_sync", this.hora);
      } else {
        this.$emit("update:hora_sync", null);
      }
    },

    timePicker() {
      if (this.timePicker) {
        this.hora = this.formatTime(this.timePicker);
      }
    },

    hora_sync: {
      handler() {
        if (this.hora_sync && this.hora_sync != '00:00') {
          this.hora = this.hora_sync;
          this.timePicker = this.formatTime(this.hora);
        }
      },

      immediate: true,
    },
  },

  methods: {
    selectedHour(hour) {
      this.timePicker = `${hour}:00`;
      this.hora = this.timePicker;
    },

    allowedHours(hour) {
      const entradaHora = this.entrada.getHours();
      const saidaIntervaloHora = this.saida_intervalo.getHours();
      const voltaIntervaloHora = this.volta_intervalo.getHours();
      const saidaHora = this.saida.getHours();

      return (
        (hour >= entradaHora && hour <= saidaIntervaloHora) ||
        (hour >= voltaIntervaloHora && hour <= saidaHora)
      );
    },

    allowedMinutes(minute) {
      const selectedHour = parseInt(this.timePicker.split(":")[0], 10);
      if (selectedHour === this.entrada.getHours()) {
        return minute >= this.entrada.getMinutes();
      }

      if (selectedHour === this.saida_intervalo.getHours()) {
        return minute <= this.saida_intervalo.getMinutes();
      }

      if (selectedHour === this.volta_intervalo.getHours()) {
        return minute >= this.volta_intervalo.getMinutes();
      }

      if (selectedHour === this.saida.getHours()) {
        return minute <= this.saida.getMinutes();
      }

      return true;
    },

    parseTime(time, tipo) {
      if (time !== "00:00") {
        const entrada = this.formatTime(this.entrada);
        const saida_intervalo = this.formatTime(this.saida_intervalo);
        const volta_intervalo = this.formatTime(this.volta_intervalo);
        const saida = this.formatTime(this.saida);

        if (
          (time >= entrada && time <= saida_intervalo) ||
          (time >= volta_intervalo && time <= saida)
        ) {
          if (tipo === "inicio") {
            this.hora = time;
          } else {
            this.hora_fim = time;
          }
        } else {
          this.$toast.error(
            "Horário inválido, selecione um horário dentro da jornada de trabalho."
          );
          if (tipo === "inicio") {
            this.hora = "00:00";
          } else {
            this.hora_fim = "00:00";
          }
          return;
        }
      }
    },

    formatTime(date) {
      if (!(date instanceof Date)) {
        return "";
      }
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
  },

};
</script>
